import React, { useRef, useEffect } from "react"
import styled from "styled-components"
import lottie from "lottie-web"

import data from "./data.json"

import media from "../../../styles/media"

const Container = styled.div`
  width: 90vw;
  height: 50vh;
  ${media.tablet`
    width: 80vw;
    height: 80vh;
  `}
`

const Lottie = styled.div`
  width: 100%;
  height: 100%;
  svg {
    max-width: 100%;
  }
`

const Animation = () => {
  const element = useRef(null)

  useEffect(() => {
    lottie.loadAnimation({
      container: element.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: data,
    })
    return () => {
      lottie.destroy()
    }
  }, [])

  return (
    <Container>
      <Lottie ref={element}></Lottie>
    </Container>
  )
}

export default Animation
