import React from "react"

import SEO from "../components/SEO"

import NotFoundPage from "../components/NotFoundPage"

const FourZeroFour = () => (
  <>
    <SEO title="404 | Not found" />
    <NotFoundPage />
  </>
)

export default FourZeroFour
