import React from "react"
import styled from "styled-components"

import Label from "../elements/label"

import Animation from "./Animation"

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const NotFoundPage = () => {
  return (
    <Container>
      <Animation />
      <Label
        to="/"
        fontSize={1}
        fontScaled
        outerRotate={-1}
        innerRotate={-1}
        value="Return home"
        className="mouse-link"
      />
    </Container>
  )
}

export default NotFoundPage
